/* eslint-disable no-console,no-undef */
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  EventImageContainerNewUI,
  EventWrapperNewUI,
  EventsTitleNewUI
} from "./styles";
import { getTimeToShow } from "../../utils/methods";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import ReactPlayer from "react-player";
class EventVideoCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: []
    };
  }

  componentDidMount() {
    const { event } = this.props;
    this.interval = setInterval(() => {
      this.setState({
        time: getTimeToShow(event, "event"),
      });
    }, 1000);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.event !== this.props.event) {
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        this.setState({
          time: getTimeToShow(this.props.event, "event"),
        });
      }, 1000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  calculateRemainingTime = (targetDate) => {
    let parsedDate1 = new Date(targetDate);
    let parsedDate2 = new Date();
    let difference = (difference = Math.abs(parsedDate2 - parsedDate1));
    if (difference <= 0) {
      return "Target date has already passed";
    }
    let days = Math.floor(difference / (1000 * 60 * 60 * 24));
    let hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    let minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((difference % (1000 * 60)) / 1000);
    return (
      days +
      "d" +
      " " +
      hours +
      "h" +
      " " +
      minutes +
      " m" +
      " " +
      seconds +
      "s"
    );
  };

  render() {
    const { event, t, isLunchAndLearn} =this.props;
    return (
      <EventWrapperNewUI border="1px solid rgba(0, 92, 135, 0.10)" padding={isLunchAndLearn ? "0px" : "20px"}>
        <EventsTitleNewUI>{t(event.title)}</EventsTitleNewUI>
        <EventImageContainerNewUI isLunchAndLearn={isLunchAndLearn}>
          <div className="eventImageDiv">
            <ReactPlayer
              className="react-player"
              url={event.event_link}
              onEnded={this.props.updateUserPoints}
              height="100%"
              width={"100%"}
              controls
            />
          </div>
        </EventImageContainerNewUI>
      </EventWrapperNewUI>
    );
  }
}

EventVideoCard.propTypes = {
  event: PropTypes.object.isRequired,
  t: PropTypes.func,
  updateUserPoints: PropTypes.func,
  isLunchAndLearn: PropTypes.bool,
};

export default withRouter((withTranslation()(EventVideoCard))
);
