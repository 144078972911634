/* eslint-disable no-console,no-undef, camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import dayjs from 'dayjs';
import LazyImage from '../common/LazyImage/LazyImage';
import { ImageUrl } from '../../utils/constants';

const TimerContainer = styled.div`
  display: flex;
  height: 48px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex: 1 0 0;
  border-radius: 4px;
  color: #005C87;
  text-align: center;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  ${'' /* .box{
  animation: animName 2s linear infinite;
}

@keyframes animName {
 0%{
    transform: rotate(0deg);
   }
100%{
    transform: rotate(360deg);
   }
} */}
  ${'' /* background: rgba(0, 92, 135, 0.10); */}
`;

const Timer = ({ data }) => {
  const [timeLeft, setTimeLeft] = useState({
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00',
    isExpired: false,
  });

  useEffect(() => {
    const updateCountdown = (date) => {
      const targetDate = dayjs(date);
      const now = dayjs();
      const timeDifference = targetDate.diff(now);

      if (timeDifference < 0) {
        setTimeLeft((prevState) => ({ ...prevState, isExpired: true }));
        clearInterval(interval);
        return;
      }

      const days = String(targetDate.diff(now, 'day')).padStart(2, '0');
      const hours = String(targetDate.diff(now, 'hour') % 24).padStart(2, '0');
      const minutes = String(targetDate.diff(now, 'minute') % 60).padStart(2, '0');
      const seconds = String(targetDate.diff(now, 'second') % 60).padStart(2, '0');

      setTimeLeft({
        days,
        hours,
        minutes,
        seconds,
        isExpired: false,
      });
    };

    const interval = setInterval(() => updateCountdown(data), 1000);
    updateCountdown(data);

    return () => clearInterval(interval);
  }, [data]);

  const { days, hours, minutes, seconds, isExpired } = timeLeft;

  if (isExpired) {
    return (
      <TimerContainer>
        <span>00d</span>
        <span>00h</span>
        <span>00m</span>
        <span>00s</span>
      </TimerContainer>
    );
  }

  return (
    <TimerContainer>
      <LazyImage div={"box"} src={ImageUrl + "/event-section/timer.svg"}/>
      <span>Starts in : </span>
      <span>{days}d</span>
      <span>{hours}h</span>
      <span>{minutes}m</span>
      <span>{seconds}s</span>
    </TimerContainer>
  );
};

Timer.propTypes = {
  data: PropTypes.object.isRequired
};

export default connect(null, null)(Timer);
